import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
// import SecureLS from 'secure-ls'

// const ls = new SecureLS({ encodingType: 'aes', isCompression: false, encryptionSecret: 'vcy6u05eq7Vi+MKssWx+Ly0Tm5lHAwjmwXTHDnMU/bI=' })
// const ls = new SecureLS({ isCompression: false })

export const stores = defineStore({
  id: 'crm_store',
  state: () => (
    useStorage('appmodulos', {
      colores: null,
      rules: null,
      user: null,
      token: { id: 0, text: '' },
      apiUrl: process.env.VUE_APP_INI,
      prefix: process.env.VUE_APP_PREFIX,
      auth: false,
      expira: null,
      items: null
    })
  ),
  getters: {
    getColores: (state) => state.colores,
    getRules: (state) => state.rules,
    getUser: (state) => state.user,
    getToken: (state) => state.token,
    getUrlApi: (state) => state.apiUrl + 'api/',
    getStorage: (state) => state.apiUrl + 'storage/',
    getAuth: (state) => state.auth,
    getExpira: (state) => state.expira,
    getPrefix: (state) => {
      if (state.user != null) {
        return state.user.rol.prefix
      } else {
        return ''
      }
    },
    getItems: (state) => state.items
  },
  actions: {
    setUrlApi (url) {
      this.apiUrl = url
    },
    setPrefix (prefix) {
      this.prefix = prefix
    },
    setColores (colores) {
      this.colores = colores
    },
    setRules (rules) {
      this.rules = rules
    },
    setUser (user) {
      this.user = user
      // ls.get('user'), this.user = ls.set('user', user)
    },
    setToken (token) {
      this.token.id = token.accessToken.id
      this.token.text = token.plainTextToken
    },
    setAuth (pAuth) {
      this.auth = pAuth
    },
    setExpira (pExpira) {
      this.expira = pExpira
    },
    setItems (pItems) {
      this.items = pItems
    }
    // createPersistedState
    /*
    plugins: [({
      key: 'appcrm',
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })]
    */
  }
})
