<template>
  <!-- style="height: 100vh; width: 100vw" -->
    <router-view/>
</template>
<script>
import { defineComponent, provide } from 'vue'
import { useStore } from '@/composables/store/useStore.js'
export default defineComponent({
  name: 'App',
  components: {
  },
  setup (props, context) {
    const store = useStore()
    store.setUrlApi(process.env.VUE_APP_INI)
    // store.setPrefix(process.env.VUE_APP_PREFIX)

    const user = store.getUser
    if (user === null) {
      provide('autenticacion', false)
    } else {
      provide('autenticacion', true)
    }
  }
})
</script>
